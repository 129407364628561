/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import {format, parseISO} from 'date-fns';
import {EXPERIENCE, LEADERSHIP_TEAM} from 'src/routes/paths';
import {Icon, Icons} from 'src/svgs';
import {topLevelDomain} from 'src/utils/international';
import styled from 'styled-components/macro';

import {Maybe} from 'types/generated';

export const Pre = styled.span`
  white-space: pre;
`;

export const seoTitlePrefix = 'Chief |';
export const seoTitleSuffix = '| Chief';
export const seo = {
  exploreTitle: `${seoTitlePrefix} Explore`,
  membershipAgreementTitle: `${seoTitlePrefix} Membership Agreement`,
  membershipAgreementCorporateTitle: `${seoTitlePrefix} Membership Agreement (Corporate)`,
  privacyTitle: `${seoTitlePrefix} Privacy Policy`,
  termsOfServiceTitle: `${seoTitlePrefix} Terms of Service`,
  covidPoliciesTitle: `${seoTitlePrefix} Covid Policies`,
  communityGuidelinesTitle: `${seoTitlePrefix} Community Guidelines`,
  nominateTitle: `${seoTitlePrefix} Nominations`,
  careersTitle: `${seoTitlePrefix} Careers`,
  aboutUsTitle: `${seoTitlePrefix} About Us`,
  homepage: {
    title: `${seoTitlePrefix} Professional Network for Women Executives`,
    description: `Chief is a leading professional network for women executives, giving members access to leadership insights & tools that influence today's business environment.`,
  },
  explore: {
    title: `Executive Leadership Training & Mentoring ${seoTitleSuffix}`,
    description: `Chief offers world-class executive leadership training & development through its thought-provoking events and powerful network connections. Learn more today.`,
  },
  enterprise: {
    title: `Enterprise & Corporate Leadership Programs | Training ${seoTitleSuffix}`,
    description: `Chief partners with leading companies around the world to provide their organizations with leadership programs and a network of powerful women executives.`,
  },
  apply: {
    title: `Apply for Membership ${seoTitleSuffix}`,
    description: `Apply for Chief membership to join the premier network of senior women executives. Connect, grow, and lead with peers today.`,
  },
};

export const navigation = {
  home: 'Home',
  forCompanies: 'For Companies',
  experience: 'Experience',
  enterprise: 'Enterprise',
  apply: 'Apply',
  login: 'Log In',
  aboutUs: 'About Us',
  thoughtLeadership: 'Thought Leadership',
  ariaLabels: {
    menuButton: 'Open navigation menu',
    menuIcon: 'Menu icon',
    logoLink: 'Home page link',
    logo: 'Chief logo',
  },
};

export const careersNavigation = {
  story: 'Our Story',
  values: 'Our Values',
  perks: 'Work Perks',
  team: 'Meet the Team',
  roles: 'See Open Roles',
};

export const careers = {
  header: {
    title: 'Join the Chief team and help change the face of leadership',
    description:
      'We’re building the next generation of leaders, one teammate at a time. We’re working to hire and develop the best talent around, and investing to make sure that Chief is a top destination for opportunity and growth. ',
    buttonText: 'See Open Roles',
  },
  press: {
    fastCompany: 'Top Ten Most Innovative Workplace Companies 2021',
    linkedIn: 'Top Startups 2020',
  },
  story: {
    title: 'Our Story',
    topDescription:
      'Chief is a private network designed for the most powerful women in leadership to strengthen their leadership, magnify their influence, and pave the way to bring others with them. Founded by Carolyn Childers and Lindsay Kaplan in 2019, we’re on a mission to drive more women to the top — and keep them there.',
    topImage:
      'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639152568/public-site/careers-page/106605814-1594130049823chieffounders_1_qeeyph',
    bottomDescription:
      'Diversity, equity, inclusion, and belonging are in our DNA, guiding everything we do and how we work. Knowing that our people are the most important driver of our success, our culture lifts voices, clears barriers, builds trust, and fosters a sense of belonging for all.',
    bottomImage:
      'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639152577/public-site/careers-page/1580861108058_1_1_tx8hqy',
  },
  values: {
    title: 'Our Values',
    description:
      'Our values are the guiding principles that bond us as a team. They are our bedrock, giving us a shared sense of purpose as we work together to achieve our mission to change the face of leadership.',
    list: [
      {number: '01', title: 'We are powerful.'},
      {number: '02', title: 'We are inclusive.'},
      {number: '03', title: 'We are real.'},
      {number: '04', title: 'We are curious.'},
      {number: '05', title: 'We time travel.'},
    ],
  },
  perks: {
    whyWorkAtChief: 'Why Work at Chief?',
    whyWorkAtChiefDescription:
      'Our benefits program is designed to ensure that our team members are healthy, supported, and able to do their best work and live their best lives.',
    compensationHeader: 'Competitive Compensation & Equity ',
    compensationDescription:
      'We recognize that our team’s contributions are key to our success. That’s why we pay our team well, provide bonuses, offer 401K, and grant stock options to every new full-time hire.',
    wellnessHeader: 'Health & Wellness',
    wellnessDescription:
      'We fully fund health insurance costs, and partner with Kindbody, One Medical, and Talkspace to support our employees’ overall wellbeing.',
    timeOffHeader: 'Time Off',
    timeOffDescription:
      'During the pandemic we instituted — and then kept — a 4.5-day workweek. We offer flexible PTO, as well as 10 paid holidays. We also take time off at the end of each year as a company to rest and spend time with our loved ones.',
    parentalLeaveDescription:
      'Our inclusive benefits program offers three months off — fully paid — for all new parents, plus a customizable reboarding program to support your return to work. ',
    parentalLeaveHeader: 'Gender-Neutral Parental Leave',
    virtualFirstHeader: 'Virtual-First Work',
    virtualFirstDescription:
      'Our employee experience is inclusive, empowering, and engaging, whether you are working from one of our Flagships, our New York City office, or the comfort of home.',
    employeeResourceGroupHeader: 'Employee Resource Groups',
    employeeResourceGroupDescription:
      'We are home to ERGs for employees who identify as AAPI, Black, LGBTQIA+, and Non-American.',
  },
  team: {
    title: 'Meet Our Team',
    testimonials: [
      {
        name: 'Ashvini Rao',
        title: 'VP Ops',
        quote:
          "“I've heard our members describe Chief as ‘life-changing.’ I wake up every morning energized to provide more women with this incredible experience. Our members are already amazing and helping them build their board of trusted advisors just fuels their rocketship!”",
        imageSrc:
          'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639085168/public-site/careers-page/testimonial-photos/Rectangle_1641_dtpktt',
      },
      {
        name: 'Erica Kantor',
        title: 'Engineering Manager',
        quote:
          '“I am so grateful to work for a company where I am surrounded by so many intelligent, driven, and motivated people. It is so exciting to work somewhere where I am encouraged to continue learning and growing both personally and professionally every day.”',
        imageSrc:
          'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639085975/public-site/careers-page/testimonial-photos/erica_uti647',
      },
      {
        name: 'Anna-Marie Sacco',
        title: 'Team Lead, MDR',
        quote:
          '“Chief is the first company I’ve worked for that I’m excited to log in to work for everyday. Not only because of the mission, but because every person on the team is a joy to work with. It’s exciting to spotlight the joy of Chief to the women who become part of our network!”',
        imageSrc:
          'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639085168/public-site/careers-page/testimonial-photos/Ellipse_3_tbpvxr',
      },
      {
        name: 'Shashank Pradhan',
        title: 'Senior Product Manager',
        quote:
          '“Our mission of connecting and supporting women leaders was one of the primary reasons I decided to join Chief. I’ve had the privilege to join one of the most diverse and talented Product teams in my career. Our teams are empowered to collaborate across the business to curate the best experience for our Chief members.”',
        imageSrc:
          'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1639085168/public-site/careers-page/testimonial-photos/Rectangle_1620_obq5yg',
      },
    ],
  },
  roles: {
    title: 'Come help us make a difference',
    buttonText: 'See All Open Roles',
    fetchError: 'Something went wrong. Please try again later.',
  },
};

export const footer = {
  link: {
    commitmentToInclusion: 'Commitment to Inclusion',
    experience: 'Explore Membership',
    faq: 'FAQs',
    enterprise: 'Enterprise',
    membershipCriteria: 'Membership Criteria',
    memberSite: 'Member Site',
    chiefUpdates: 'Chief Updates',
    theNewEra: 'The New Era of Leadership',
    thoughtLeadership: 'Thought Leadership',
    careers: 'Careers',
    externalCommunicationsPolicy: 'External Communications Policy',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    clubhouses: 'Clubhouses',
    eventRental: 'Event Rental',
    executiveCoaching: 'Executive Coaching',
    leadershipTeam: 'Leadership Team',
    press: 'Press',
  },
  heading: {
    joinChief: 'Join CHIEF',
    resources: 'Resources',
    company: 'Company',
  },
  email: {
    pressEmailAddress: `press@chief.com`,
    pressEmailLabel: 'For press inquiries',
    generalEmailAddress: `hello@chief.com`,
    generalEmailLabel: 'For general inquiries',
  },
  newsletter: {
    title: 'Subscribe to Chief in Brief for the latest in thought leadership',
    subscribeButton: 'Subscribe',
    status: {
      success: 'Thank you for subscribing',
      error: 'Please enter a valid email address',
      submissionError: 'There was an error submitting your subscription.',
    },
  },
};

export const hero = {
  exploreButton: 'Explore Membership',
  scrollLabel: {
    mobile: 'More',
    desktop: 'Scroll',
  },
  ariaLabels: {
    heading: 'Women have always been powerful',
    scrollIndicatorIcon: 'Arrow pointing down',
  },
  subtitle:
    'Chief is the only private membership network focused on connecting and supporting women executive leaders.',
};

export const logoSection = {
  heading1: 'A private network for',
  heading2: 'the most powerful women in leadership',
  subtitle: (
    <>
      Connect with a powerful rolodex of senior executives from diverse backgrounds, industries, and organizations —
      from Fortune 50 companies to successful startups.
    </>
  ),
  ariaLabels: {
    accenture: 'accenture',
    allstate: 'allstate',
    amazon: 'amazon',
    audible: 'audible',
    bloomberg: 'bloomberg',
    cartier: 'cartier',
    chanel: 'chanel',
    chobani: 'chobani',
    citi: 'citi',
    dell: 'dell',
    discovery: 'discovery',
    disney: 'disney',
    doordash: 'doordash',
    ebay: 'ebay',
    facebookRound: 'facebookRound',
    forbes: 'forbes',
    google: 'google',
    goldmanSachs: 'goldman sachs',
    hbo: 'hbo',
    headspace: 'headspace',
    helloFresh: 'helloFresh',
    hulu: 'hulu',
    ibm: 'ibm',
    inc500: 'inc 500',
    johnsonAndJohnson: 'johnson and johnson',
    jordans: 'jordans',
    loreal: 'loreal',
    lvmh: 'lvmh',
    mercedes: 'mercedes',
    morganStanley: 'morganStanley',
    nbc: 'nbc universal',
    netflix: 'netflix',
    newYorkTimes: 'new york times',
    nike: 'nike',
    pfizer: 'pfizer',
    publicisGroupe: 'publicisGroupe',
    salesforce: 'salesforce',
    samsung: 'samsung',
    sony: 'sony',
    sonyMusic: 'sony music',
    spotify: 'spotify',
    square: 'square',
    squarespace: 'squarespace',
    time: 'time',
    todayShow: 'today show',
    toyota: 'toyota',
    uber: 'uber',
    unitedHealthGroup: 'unitedHealthGroup',
    universal: 'universal music group',
    verizon: 'verizon',
    walmart: 'walmart',
    wallStreetJournal: 'wall street journal',
  },
};

export const driveSection = {
  heading1: 'Chief drives women to the top',
  heading2: 'and keeps them there',
  body: (
    <>
      Connect to a network of leading women. Build a personal board of advisors. Unlock access to prominent business
      experts and programming. <Pre>Grow as a leader.</Pre>
    </>
  ),
};

export const quoteSection = {
  heading1: 'Chief is changing the',
  heading2: 'face of leadership',
  todayShow: {
    quote: 'Aligning female executives to shatter the glass ceiling and raise up all women.',
    logoAriaLabel: 'the today show',
  },
  wallStreetJournal: {
    quote: 'The private group where C-Suite women go to power up.',
    logoAriaLabel: 'wall street journal',
  },
  cnn: {
    quote: 'The private club for women executives that helps them ascend to the top ranks and stay there.',
    logoAriaLabel: 'cnn',
  },
  inc500: {
    quote: 'This Private Women’s Club Has Plans for World Domination (but Shhh — It’s Hush Hush)',
    logoAriaLabel: 'inc 500',
  },
  cnbc: {
    quote: 'Chief, a private network for C-Suite women, is expanding amid the pandemic.',
    logoAriaLabel: 'cnbc',
  },
  forbes: {
    quote: 'Chief provides mentorship, coaching programs and, most importantly, peer access.',
    logoAriaLabel: 'forbes',
  },
};

export const homeCtaSection = {
  heading: 'Interested in becoming a member?',
  explore: 'Explore',
  apply: 'Apply',
};

export const applyPage = {
  heading: 'Apply for membership',
  headerColumnLeft: {
    title: 'Membership Criteria',
    body: 'Our goal is to help women executives maximize their leadership impact through access to a vetted executive community and valuable insights.',
    button: 'Learn More',
  },
  headerColumnRight: {
    title: 'Commitment to Inclusion',
    body: `We strive to build a diverse community that serves multiple and intersecting identities.`,
    button: 'Learn More',
  },
  submittedHeading: 'Application Submitted',
  submittedBody:
    'Our team evaluates the strength, length, and impact of an applicant’s career, and strives to give priority to the most impactful and diverse candidates across background, industry, and role. We will reach out to you with next steps if your application qualifies for a membership interview. Thank you for helping advance and grow our mission.',
  submittedCta: `Back to chief${topLevelDomain}`,
  successMessage: 'Thank you for applying to Chief.',
  disclaimer:
    'The information collected allows us to evaluate your candidacy for Chief membership. You may unsubscribe at any time. For more information, see our Privacy Policy.',
  headlines: {
    membership: {
      headline: 'Membership Criteria',
      description:
        'Our goal is to help women executives maximize their leadership impact through access to a vetted executive community and valuable insights.',
      cta: 'Learn More',
    },
    inclusion: {
      headline: 'Commitment to Inclusion',
      description: 'We strive to build a diverse community that serves multiple and intersecting identities.',
      cta: 'Learn More',
    },
  },
};

export const exploreHero = {
  heading: 'Membership Designed for Today’s Leaders',
  body: (
    <>
      Chief is designed for women in the C-Suite, senior executives, and accomplished VPs to strengthen their
      leadership, magnify their influence, and pave the way to bring others with them.
    </>
  ),
};

export const exploreNetwork = {
  subheading: 'Rolodex Upgrade',
  heading: 'The Network',
  body: 'Get instant access to a vetted national community of professional peers who understand your challenges and needs.',
};

export const exploreCoreGroup = {
  nodeAriaLabel: 'group icon',
  subheading: 'Peer Support',
  heading: 'Core Group',
  body: 'Navigate and accelerate your journey as a leader in a hyper-curated peer group, facilitated by an executive coach.',
};

export const exploreMasterClass = {
  subheading: 'Masterclasses',
  heading: 'Workshops & Conversations',
  body: 'Access cutting-edge leadership resources designed for senior executives and led by renowned business school professors, economists, and diversity experts — available live and on demand.',
  classes: [
    'Operationalizing DEI Strategies',
    'Building for Billions',
    'Leading By Empowering Others',
    'Media Training Masterclass',
    'The 2021 Global Economic Recovery',
    'The Future of Digital Disruption',
    'Shifting Awareness into Action',
    'Strengthening Network Effects',
  ],
};

export const exploreSpeakers = {
  subheading: 'Exclusive Access',
  heading: 'The Power Seat',
  body: (
    <>
      Gain insider knowledge and insights from business leaders and cultural icons. Past guests have included: Amal
      Clooney, Mellody Hobson, Indra Nooyi, <Pre>Diane von Furstenberg</Pre>, Kara Swisher, Gloria Estefan,{' '}
      <Pre>Adam Grant, and Gayle King.</Pre>
    </>
  ),
};

export const exploreApp = {
  subheading: 'Connections',
  heading: 'The Chief Platform',
  sections: [
    {
      heading: 'Community Groups',
      body: 'Join industry-, role-, and identity-based groups to discuss business-critical topics and trends.',
      image:
        'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1620694408/public-site/app-community_bqnhko',
      imgLabel: 'Image of the Chief app displaying social posts',
    },
    {
      heading: 'Member Meetups',
      body: 'Come together with leaders throughout the Chief community at virtual and in-person gatherings.',
      image:
        'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1620694408/public-site/app-talent_cw928p',
      imgLabel: 'Image of the Chief App displaying postings',
    },
    {
      heading: 'The Intro',
      body: 'Connect with like-minded executives to cross-pollinate ideas and networks.',
      image:
        'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto/v1620694408/public-site/app-intro_gh2kvc',
      imgLabel: 'Image of the Chief App displaying a step-by-step process: 1-2-3',
    },
  ],
};

export const exploreFlagship = {
  subheading: 'Clubhouses',
  heading: 'Flagship Access',
  body: (
    <>
      With exclusive clubhouses in major cities, Chief members can host meetings, enjoy drinks, and connect with other
      members. Chief’s Flagships are located in New York, Los Angeles, Chicago, <Pre>and San Francisco.</Pre>
    </>
  ),
  images: [
    {
      id: '1',
      label: 'Photo of New York clubhouse',
      src: 'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto,q_auto:good/v1623349621/public-site/clubhouse-slides/new-york-clubhouse_zfjj8c',
    },
    {
      id: '2',
      label: 'Photo of Los Angeles clubhouse',
      src: 'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto,q_auto:good/v1620842720/public-site/Fox_Harrison_Chief_Interior_RUSH-15_websize-4_uu4vkw',
    },
    {
      id: '3',
      label: 'Photo of Chicago clubhouse',
      src: 'https://res.cloudinary.com/hfses70kr/image/upload/fl_lossy,f_auto,q_auto:good/v1620842721/public-site/Fox_Harrison_Chief_Interior_RUSH-15_websize-5_u544ti',
    },
  ],
};

export const exploreCtaSection = {
  heading: 'Interested in becoming a member?',
  apply: 'Apply',
};

export const newsletterForm = {
  emailPlaceholder: {
    mobile: 'example@email.com',
    desktop: 'Enter your email to subscribe for the latest',
  },
  emailLabel: {
    mobile: 'Enter your email to subscribe for the latest',
  },
  subscribeButtonText: 'Subscribe',
  successMessage: 'Thank you for subscribing!',
};

export const privacyPolicy = {
  title: 'Chief Privacy Policy',
  effectiveDateLabel: 'Effective Date',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const cookieNotice = {
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const termsOfService = {
  companyName: 'Chief',
  title: 'Terms of Use Agreement',
  lastUpdatedLabel: 'Last Updated Date',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const membershipAgreement = {
  title: 'Chief Membership Agreement',
  versionLabel: 'Version',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const membershipAgreementCorporate = {
  heading: 'Corporate Partnership',
  subheading: 'Payment Terms and Conditions',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const covidPolicies = {
  title: 'COVID-19 Policies & Procedures: General Use',
  lastUpdateDateLabel: 'Last Updated',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

export const arrowLink = {
  ariaLabels: {
    arrowIcon: 'Right arrow',
  },
};

export const modal = {
  ariaLabels: {
    closeIcon: 'Close modal icon',
  },
};

export const navDrawer = {
  ariaLabels: {
    closeIcon: 'Close drawer icon',
    rightChevronIcon: 'right arrow icon',
  },
};

export const socialMediaLinks = {
  ariaLabels: {
    emailIcon: 'email share icon',
    facebookIcon: 'facebook share icon',
    linkedInIcon: 'linkedin share icon',
    twitterIcon: 'twitter share icon',
  },
};

export const pageNotFound = {
  title: '404',
  heading: (
    <>
      Hmmm, it appears <Pre>this page doesn’t exist.</Pre>
    </>
  ),
  subtitle: 'May we recommend one of the following?',
  backButton: 'Go Back',
  homeButton: 'Return Home',
};

export const communityGuidelines = {
  title: 'Community Guidelines',
  lastUpdatedLabel: 'Last Updated:',
  ariaLabels: {
    logo: 'Chief logo',
  },
};

const formattedEditorialPublishDate = (date: Maybe<string>): string | null => {
  if (!date) return null;
  return format(parseISO(date), 'MMMM d, yyyy');
};

export const editorial = {
  galleryTitle: 'Gallery',
  exploreTitle: 'Explore',
  nextArticle: 'Next Up',
  byline: (provided: string): string => `By ${provided}`,
  notFound: 'Editorial article with the provided slug was not found.',
  mostRecentPublishDate: (date: Maybe<string>): string => `Published ${formattedEditorialPublishDate(date)}`,
};

export const aboutUs = {
  diversity: {
    heading: 'Our Commitment to Inclusion',
    description:
      "We believe the diversity of our community makes us exponentially more impactful and our vision is a future where one's race, gender identity/expression, sexual orientation, disability, or nationality is not a barrier to leadership.",
    cta: 'Read More',
  },
  executives: {
    heading: 'Executive Team',
    execCta: 'View Bio',
    cta: 'View Full Bios',
    execs: [
      {
        name: 'Alison Moore',
        title: 'CEO',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/alison_moore.png',
        bioUrl: LEADERSHIP_TEAM + '#Alison-Moore',
      },
      {
        name: 'Trey Boynton',
        title: 'Chief People & Diversity Officer',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/trey_boynton.png',
        bioUrl: LEADERSHIP_TEAM + '#Trey-Boynton',
      },
      {
        name: 'Saana Rapakko Hunt',
        title: 'Chief Product and Experience Officer',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/saana_rapakko_hunt.png',
        bioUrl: LEADERSHIP_TEAM + '#Saana-Rapakko-Hunt',
      },
      {
        name: 'Bridget Winston',
        title: 'Chief Customer Officer',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/bridget_winston.png',
        bioUrl: LEADERSHIP_TEAM + '#Bridget-Winston',
      },
      {
        name: 'Erika Johnson',
        title: 'Senior Vice President of Member Experience',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/erika_johnson.png',
        bioUrl: LEADERSHIP_TEAM + '#Erika-Johnson',
      },
      {
        name: 'Chrissy Lo',
        title: 'Vice President of Finance',
        pictureUrl: 'https://assets.chief.com/lohp/images/executives/chrissy_lo.png',
        bioUrl: LEADERSHIP_TEAM + '#Chrissy-Lo',
      },
    ],
  },
  maximize_impact: {
    headingStrings: [`We're on a mission to`, 'maximize the impact', 'of women executives.'],
    description: `Chief's Co-Founders Carolyn Childers and Lindsay Kaplan founded Chief out of their own experiences as the “only” in senior leadership. They saw an opportunity to bring community, leadership growth, and guidance to an extremely overlooked audience with the power to create change from the top: women executives.`,
  },
  news: {
    heading: 'Chief News',
    cta: 'View All',
    items: [
      {
        title: 'New clubhouse for women business executives lands in D.C.',
        url: 'https://www.axios.com/local/washington-dc/2024/01/18/chief-clubhouse-women-business-network-dc',
        year: '2024',
        source: 'Axios',
      },
      {
        title: 'Unraveling the complexities of remote work',
        url: 'https://finance.yahoo.com/video/unraveling-complexities-remote-220054206.html',
        year: '2024',
        source: 'Yahoo!',
      },
      {
        title: 'Battling ageism in the workplace',
        url: 'https://finance.yahoo.com/video/battling-ageism-workplace-210020713.html',
        year: '2024',
        source: 'Yahoo!',
      },
      {
        title: 'Chief: 2023 TIME100 Most Influential Companies ',
        url: 'https://time.com/collection/time100-companies-2023/6285117/chief/',
        year: '2024',
        source: 'TIME',
      },
      {
        title: 'Female Founders 200: Meet the Women Building the Companies of Tomorrow ',
        url: 'https://www.inc.com/profile/carolyn-childers',
        year: '2024',
        source: 'Inc.',
      },
      {
        title: 'The New Perk For Women Executives: Membership In This Exclusive Group ',
        url: 'https://www.forbes.com/sites/jenamcgregor/2023/01/24/the-new-perk-for-women-executives-membership-in-this-exclusive-group/',
        year: '2024',
        source: 'Forbes',
      },
    ],
  },
  we_believe: {
    heading: 'We believe',
    strings: [
      'great leadership is essential because change starts at the top.',
      'diverse perspectives fuel stronger decision-making.',
      'leadership is a practice, not a person.',
      'that modern leaders are lifelong learners.',
    ],
  },
};

export const homepage = {
  articles: {
    heading: 'Thought Leadership',
    cta: 'View All',
    articles: [
      {
        pictureUrl: 'https://assets.chief.com/lohp/images/homepage/articles/article_1.png',
        title: `Ken Chenault on Why Leaders Need Empathy and a 'High Level of Self-Awareness' During Challenging Times`,
        articleUrl:
          '/articles/ken-chenault-on-why-leaders-need-empathy-and-a-high-level-of-self-awareness-during-challenging-times',
      },
      {
        pictureUrl: 'https://assets.chief.com/lohp/images/homepage/articles/article_2.png',
        title: `Over Half of CEOs Expect Leadership to Be More Challenging in 2024 — Here's Why`,
        articleUrl: '/articles/over-half-of-ceos-expect-leadership-to-be-more-challenging-this-year-heres-why',
      },
      {
        pictureUrl: 'https://assets.chief.com/lohp/images/homepage/articles/article_3.png',
        title: 'From Gen Z to X and Boomers, Too: How to Effectively Lead a Multi-Generational Workforce',
        articleUrl: '/articles/from-gen-z-to-x-and-boomers-too-how-to-effectively-lead-a-multi-generational-workforce',
      },
    ],
  },
  hero: {
    heading1: 'Raise your',
    heading2: 'leadership',
    heading3: 'game.',
    ariaLabel: 'Raise your leadership game',
    description: `As the largest network of senior women executives, Chief gives members the vetted network and leadership insights they need to drive impact in today's rapidly changing business environment.`,
    cta: 'Explore Membership',
  },
  clients: {
    heading1: `It's not just`,
    heading2: 'what',
    heading3: `you know, it's`,
    heading4: 'who',
    heading5: 'you know',
    description: `Join a vetted community made up of C-Suite and VP executives across industries driving impact from the top.`,
    stats: [
      {
        stat: '10,000+',
        description: 'companies represented',
      },
      {
        stat: '77%',
        description: 'of the Fortune 100',
      },
      {
        stat: '40%',
        description: 'of members are in the C-Suite',
      },
    ],
  },
  impact: {
    heading: `Chief Drives Impact`,
    stats: [
      {
        stat: '9 in 10',
        description: 'of members say Chief advanced their careers',
      },
      {
        stat: '200+',
        description: 'new board seats',
      },
      {
        stat: '2,000+',
        description: 'executive promotions',
      },
    ],
    featuredHeading: 'Featured in',
  },
  invest: {
    heading: 'Invest in your leadership',
    description:
      'Choose a membership option tailored to your needs as an executive leader, with specialized services that help you forge connections across a national community.',
    communityAspects: [
      {
        heading: 'Executive Advisory',
        icon: <Icon icon={Icons.homepageAdvisory} aria-label="Executive Advisory" />,
        url: EXPERIENCE,
      },
      {
        heading: 'Executive Coaching',
        icon: <Icon icon={Icons.homepageCoaching} aria-label="Executive Coaching" />,
        url: EXPERIENCE,
      },
      {
        heading: 'Executive Education',
        icon: <Icon icon={Icons.homepageEducation} aria-label="Executive Education" />,
        url: EXPERIENCE,
      },
    ],
    communityAspectsHeading: 'The Chief Community',
    cta: 'Explore Membership',
  },
  networking: {
    heading1: `Networking is not just a necessity—it's a shortcut to`,
    heading2: `success`,
    cta: 'Become a member',
  },
  testimonials: [
    {
      quoteStrings: ['“Chief has been', 'well worth', 'the investment.”'],
      attribution: 'Brita Turner, Global Head of M&A Integration, eBay',
    },
    {
      quoteStrings: ['“I lead with more', 'confidence', '.”'],
      attribution: 'Joy Yokley, Managing Director, PwC',
    },
  ],
};

export const experience = {
  hero: {
    headingStrings: ['Stay', 'on top', 'of your game.'],
    ariaLabel: 'Stay on top of your game',
    description:
      'The business landscape is changing rapidly. So are the demands of senior leadership. Chief offers executive development, thought-provoking events, and powerful connections to make you a stronger leader.',
  },
  membership_options: {
    headingStrings: [`Leadership isn't one-size-fits-all`, '—', 'neither is Chief.'],
    cta: 'Become A Member',
    description: 'Choose a membership tailored to meet your needs.',
    options: [
      {
        heading: 'Executive Advisory',
        icon: <Icon icon={Icons.experienceAdvisory} aria-label="Executive Advisory" />,
        iconModal: <Icon icon={Icons.experienceAdvisoryModal} aria-label="Executive Advisory" />,
        subHeading: 'For the executive looking for a personal board of advisors',
        description:
          'Navigate leadership challenges alongside 8-12 hand-picked peers, tapping into your group’s accountability and collective wisdom.',
        quote: `“Core has been one of the bright spots for me of Chief—we talk about our Core Group as our personal board of advisors. It gives you confidence in your alignment, your focus, and your vision.”`,
      },
      {
        heading: 'Executive Coaching',
        icon: <Icon icon={Icons.experienceCoaching} aria-label="Executive Coaching" />,
        iconModal: <Icon icon={Icons.experienceCoachingModal} aria-label="Executive Coaching" />,
        subHeading: 'For the executive looking for personalized leadership guidance',
        description: 'Tackle timely leadership challenges with personalized guidance from a vetted executive coach.',
        stat: {
          stat: '96%',
          descriptionStrings: ['of members say Executive Coaching is', 'very or extremely valuable'],
        },
        quote: `“This opportunity has convinced me that all executives should have access to a coach.”`,
      },
      {
        heading: 'Executive Education',
        icon: <Icon icon={Icons.experienceEducation} aria-label="Executive Education" />,
        iconModal: <Icon icon={Icons.experienceEducationModal} aria-label="Executive Education" />,
        subHeading: 'For the executive looking to strengthen their foundational leadership skills',
        description:
          'Strengthen your key leadership competencies through live multi-part courses led by world-renowned academics.',
        stat: {
          stat: '97%',
          descriptionStrings: [
            'of participants say they have',
            'more impact as a leader',
            'after their Executive Education course',
          ],
        },
        quote: `“The instructor was fantastic and provided very tangible, relevant actions that can be applied to immediately make an impact.”`,
      },
    ],
    option_cta: 'Learn More',
    option_quote_heading: `In our members' words`,
    option_quote_cta: 'Become A Member',
  },
  community_perks: {
    headingStrings: ['A', 'community', 'of peers with personalized guidance.'],
    description:
      'Every member gains access to a vetted network and curated insights, tapping into expertise, wisdom, opportunities, and connections.',
    perks: [
      {
        heading: 'Community experiences',
        perks: [
          'In-person and virtual gatherings',
          'A digital platform',
          'Annual one-day summits in major U.S. cities',
          'Clubhouses in major business hubs ',
        ],
      },
      {
        heading: 'Concierge team',
        perks: [
          'Personalized one-on-one onboarding',
          'A team of dedicated advisors who understand your leadership goals',
        ],
      },
    ],
  },
  invest: {
    headingStrings: ['Invest in', 'yourself.'],
    cta: 'Become A Member',
  },
  testimonials: [
    {
      quoteStrings: [
        '“Chief has been important in allowing me to maintain',
        'my inspiration, my positivity, my ability',
        'to center my own worth as a woman leader. The importance of Chief, particularly now, is really critical.”',
      ],
      attribution: 'Vignetta Charles, CEO, ETR',
    },
    {
      quoteStrings: [
        '“Chief is a critical piece of how to stay',
        'on top of your game',
        ', and continue to grow and change in different work environments.”',
      ],
      attribution: 'Sara Diegnan, VP of Customer Experience, ChartHop',
    },
  ],
  membership_criteria: {
    heading: 'Membership criteria',
    description:
      'Chief membership is designed for those who have senior leadership responsibility within their organization. We vet applicants for executive seniority to ensure our members can get support from true peers. We use a range of factors to determine if your seniority level qualifies, including:',
    cta: 'Learn More',
    criteria: [
      'Title',
      'Reporting structure',
      'Industry',
      'Size of team managed',
      'Company size',
      'Professional journey',
    ],
    membership_rate: 'Chief membership starts at ...',
    membership_rate_cta: 'Become A Member',
  },
};

export const enterprise = {
  hero: {
    headingStrings: ['Drive', 'business results', 'for your executives'],
    ariaLabel: 'Drive business results for your executives',
    description:
      'Equip your leadership team with a network of powerful women executives from 10,000+ companies, and unlock new growth opportunities for your business.',
    cta: 'Start A Conversation',
  },
  orgs: {
    heading: 'Join 80+ leading organizations that have partnered with Chief Enterprise',
  },
  for_the: {
    heading: 'For the',
    strings: [
      'company that wants to retain diverse executives',
      'L&D leader who wants to augment their in-house program',
      'the DEI leader who wants to equip their leaders with diverse external communities and insights',
    ],
  },
  stats: {
    stats: [
      {
        heading: 'Retain your women executives.',
        description:
          'Demonstrate your commitment and investment in your women leaders, providing them with a network of peers who can provide open doors and offer insights.',
        stat: '1.5x',
        statDetail: 'the cost of replacing an employee versus their annual salary.',
        statSource: 'Gallup',
      },
      {
        heading: 'Address challenges and access innovation.',
        description:
          'The Chief network comprises diverse experts who can help your company discover new ways to save costs and solve your leaders’ biggest challenges.',
        stat: '74%',
        statDetail: 'of women leaders said they save money for their team or organization through networking.',
        statSource: 'Morning Consult/Chief study',
      },
      {
        heading: 'Grow business connections.',
        description:
          'Women leaders gain access to global executives across industries, which can translate into better opportunities for both themselves and your organization.',
        stat: '85%',
        statDetail: 'of women leaders believe networking leads to new business opportunities.',
        statSource: 'Morning Consult/Chief study',
      },
    ],
  },
  services: {
    heading: 'Featured services for your leaders',
    primary: {
      heading: 'Executive Development',
      description:
        'Modern leaders are never done learning. Our executive development services provide a safe, trust-worthy environment where your executives can tackle challenges and hone their leadership skills.',
      services: [
        {
          heading: 'Executive Advisory',
          icon: <Icon icon={Icons.enterpriseAdvisory} aria-label="Executive Advisory" />,
          description:
            'Helps your leaders navigate leadership challenges alongside a handpicked group of 8-12 peers who meet monthly, fostering accountability and collective wisdom.',
        },
        {
          heading: 'Executive Coaching',
          icon: <Icon icon={Icons.enterpriseCoaching} aria-label="Executive Coaching" />,
          description:
            'Helps your leaders tackle timely, sensitive leadership challenges with eight one-on-one sessions with a vetted executive coach.',
        },
        {
          heading: 'Executive Education',
          icon: <Icon icon={Icons.enterpriseEducation} aria-label="Executive Education" />,
          description:
            'Helps your leaders strengthen their foundational leadership skills through live workshops with top business academics.',
        },
      ],
    },
    secondary: {
      services: [
        {
          heading: 'A Curated Network',
          description:
            'Of powerful women executives. Our in-person events, digital platform, and Clubhouse spaces provide access to insights, resources, and support to maximize their potential.',
        },
        {
          heading: 'Tailored Content',
          description:
            'Including 200+ on-demand workshops on topics spanning DEI, personal leadership, career growth, industry trends, and business tactics.',
        },
      ],
    },
  },
  quotes: {
    quotes: [
      {
        name: 'Yolanda Friend',
        title: 'Managing Director North America Inclusion & Diversity, Accenture',
        pictureUrl: 'https://assets.chief.com/lohp/images/enterprise/quotes/yolanda_friend.png',
        quote:
          '“At Accenture, we foster a culture and a workplace where all our people can feel a sense of belonging, are respected, and are empowered to do their best work. We are committed to helping everyone thrive, which includes the advancement and representation of women. Collaboration with partners like Chief, facilitates meaningful connections for our executive women and provides an impactful way to engage our leaders.”',
      },
      {
        name: 'Joy Fitzgerald',
        title: 'Chief Diversity Officer, UnitedHealth Group',
        pictureUrl: 'https://assets.chief.com/lohp/images/enterprise/quotes/joy_fitzgerald.png',
        quote: `“We are excited to empower and support women leaders from across UnitedHealth Group through Chief. One of our core DEI objectives is to increase the representation of women, and specifically women of color, at the most senior levels of leadership. The relationships built through Chief's programming help our women employees excel at UnitedHealth Group, and advance the careers of their female peers around the globe.”`,
      },
    ],
  },
  invest: {
    headingStrings: ['Invest in the success of your', 'women executives.'],
    cta: 'Start A Conversation',
  },
};
