import React, {useState} from 'react';

import {Events} from 'src/analytics/events';
import {Box} from 'src/components/shared';
import {useLoadOnInteraction} from 'src/hooks/useLoadOnInteraction';
import * as externalUrls from 'src/routes/externalUrls';
import * as paths from 'src/routes/paths';
import DynamicStyledSystemComponent from 'src/styleguide/DynamicStyledSystemComponent';
import {AppleStoreIcon} from 'src/svgs/appleStoreIcon';
import {ChiefLogoIcon} from 'src/svgs/chiefLogoIcon';
import {FacebookSocialIcon} from 'src/svgs/facebookSocialIcon';
import {GooglePlayStoreIcon} from 'src/svgs/googlePlayStoreIcon';
import {InstagramSocialIcon} from 'src/svgs/instagramSocialIcon';
import {LinkedInSocialIcon} from 'src/svgs/linkedInSocialIcon';
import {XSocialIcon} from 'src/svgs/xSocialIcon';
import {YoutubeSocialIcon} from 'src/svgs/youtubeSocialIIcon';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';

import {Anchor} from '../controls/Anchor';
import {NewsletterForm} from './NewsletterForm';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterContainer = styled(Box).attrs({
  as: 'footer',
  backgroundColor: 'offBlack',
  display: 'flex',
  width: '100%',
  maxWidth: [null, null, null, '618px', '824px', '1160px'],
  minWidth: [null, null, null, '618px'],
  margin: '0 auto',
  flexDirection: ['column-reverse', null, null, 'row'],
  paddingTop: [null, null, null, '20px'],
})``;

const FooterNavigationSection = styled(Box).attrs({
  paddingY: ['40px', null],
  paddingX: ['20px', null, null, '0px', null],
  paddingLeft: [null, null, null, '70px', null],
  display: ['flex', null, null, null, 'grid', 'flex'],
  justifyContent: 'space-between',
  gridTemplateColumns: [null, null, null, null, 'auto auto', null],
  gridTemplateRows: [null, null, null, null, 'auto auto', null],
  flexDirection: ['column', null, null, null, null, 'row'],
  width: ['100%', null, null, '50%', '60%', '70%'],
  minWidth: [null, null, null, null, '522px', '857px'],
  gap: '2rem',
})``;

const SocialMediaSection = styled(Box).attrs({
  width: ['100%', null, null, '50%'],
  display: 'flex',
  flexDirection: ['column'],
  paddingRight: [null, null, null, '70px'],
  paddingLeft: [null, null, null, '0px'],
  paddingBottom: [null, null, null, '40px'],
  paddingY: ['40px', null, null, null],
  paddingX: ['20px', null, null, null, null],
  backgroundColor: [`#1A1A1A`, null, null, 'transparent'],
  gap: '32px',
})``;

const FooterLinksGroup = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
})``;

const FooterLink = styled(Anchor)`
  color: white;
  text-decoration: none;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterHeading = styled.h2`
  color: white;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1.25rem;
`;

const FooterLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const AppStoreIconsWrapper = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})``;
const SocialMediaIcons = styled(Box).attrs({
  display: 'flex',
  gap: '24px',
})``;

const EmailsContainer = styled(Box).attrs({
  width: ['100%'],
  display: 'flex',
  flexDirection: ['column', null, null, 'row'],
  justifyContent: [null, null, null, 'center'],
  color: 'cream',
  paddingBottom: ['40px', null],
  paddingY: ['1rem', null, null, '40px', null],
  paddingX: ['20px', null, null, null, null],
  backgroundColor: 'darkGreen',
  gap: '1rem',
})``;

const EmailLink = styled(Anchor)`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled(DynamicStyledSystemComponent).attrs({
  as: 'span',
  display: ['none', null, null, 'inline'],
  margin: [null, null, null, '0 0 0.625rem'],
})``;

const PixelImg = styled.img`
  height: 1px;
  width: 1px;
  border-style: 'none';
`;

const handleTrackClickNav = (item: string) => {
  Events.didClickNav({item, pageLocation: 'footer'});
};

const handleTrackClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

export const Footer: React.FC<React.ComponentProps<typeof FlexContainer>> = props => {
  const loadOnInteraction = useLoadOnInteraction();

  const [didSubmitForm, setDidSubmitForm] = useState(false);
  const handleNewsletterSubmit = () => {
    Events.didClickNav({item: 'Chief in Brief Subscribe', pageLocation: 'footer'});
    setDidSubmitForm(true);
  };
  return (
    <FlexContainer {...props}>
      {loadOnInteraction && <NewsletterForm id="footer-newsletter" onSubmit={handleNewsletterSubmit} />}
      {didSubmitForm && <PixelImg alt="" src="https://insight.adsrvr.org/track/pxl/?adv=728feju&ct=0:j9fs3l6&fmt=3" />}
      <FooterContainer>
        <SocialMediaSection>
          <ChiefLogoIcon />
          <SocialMediaIcons>
            <a
              aria-label="linkedin link"
              onClick={() => handleTrackClickLink('LinkedIn', paths.socialPaths.linkedIn)}
              href={paths.socialPaths.linkedIn}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInSocialIcon />
            </a>
            <a
              aria-label="instagram link"
              onClick={() => handleTrackClickLink('Instagram', paths.socialPaths.instagram)}
              href={paths.socialPaths.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramSocialIcon />
            </a>
            <a
              onClick={() => handleTrackClickLink('Youtube', paths.socialPaths.youtube)}
              aria-label="youtube link"
              href={paths.socialPaths.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeSocialIcon />
            </a>
            <a
              onClick={() => handleTrackClickLink('Facebook', paths.socialPaths.facebook)}
              aria-label="facebook link"
              href={paths.socialPaths.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookSocialIcon />
            </a>
            <a
              onClick={() => handleTrackClickLink('X', paths.socialPaths.x)}
              aria-label="x link"
              href={paths.socialPaths.x}
              target="_blank"
              rel="noreferrer"
            >
              <XSocialIcon />
            </a>
          </SocialMediaIcons>
          <AppStoreIconsWrapper>
            <a
              onClick={() => handleTrackClickLink('Apple App Store', paths.mobileAppPaths.appStore)}
              href={paths.mobileAppPaths.appStore}
              target="_blank"
              rel="noreferrer"
            >
              <AppleStoreIcon />
            </a>
            <a
              onClick={() => handleTrackClickLink('Google Play Store', paths.mobileAppPaths.googlePlay)}
              href={paths.mobileAppPaths.googlePlay}
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlayStoreIcon />
            </a>
          </AppStoreIconsWrapper>
        </SocialMediaSection>
        <FooterNavigationSection>
          <FooterLinksGroup>
            <FooterHeading>{copy.footer.heading.joinChief}</FooterHeading>
            <FooterLinkWrapper>
              <FooterLink onClick={() => handleTrackClickNav('Clubhouses')} s to={paths.CLUBHOUSES_OVERVIEW}>
                {copy.footer.link.clubhouses}
              </FooterLink>
              <FooterLink
                onClick={() => handleTrackClickNav('Commitment to Inclusion')}
                to={externalUrls.COMMITMENT_TO_INCLUSION}
              >
                {copy.footer.link.commitmentToInclusion}
              </FooterLink>
              <FooterLink
                onClick={() => handleTrackClickNav('Event Rental')}
                target="_blank"
                rel="noreferrer"
                to={externalUrls.EVENT_RENTAL}
              >
                {copy.footer.link.eventRental}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Executive Coaching')} to={paths.EXECUTIVE_COACHING}>
                {copy.footer.link.executiveCoaching}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Explore')} to={paths.EXPERIENCE}>
                {copy.footer.link.experience}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('FAQs')} to={paths.FAQ}>
                {copy.footer.link.faq}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('For Companies')} to={paths.ENTERPRISE}>
                {copy.footer.link.enterprise}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Membership Criteria')} to={paths.MEMBERSHIP_CRITERIA}>
                {copy.footer.link.membershipCriteria}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Member Login')} to={externalUrls.LOGIN}>
                {copy.footer.link.memberSite}
              </FooterLink>
            </FooterLinkWrapper>
          </FooterLinksGroup>
          <FooterLinksGroup>
            <FooterHeading>{copy.footer.heading.resources}</FooterHeading>
            <FooterLinkWrapper>
              <FooterLink onClick={() => handleTrackClickNav('Updates')} to={externalUrls.UPDATES_BLOG}>
                {copy.footer.link.chiefUpdates}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('The New Era')} to={externalUrls.THE_NEW_ERA}>
                {copy.footer.link.theNewEra}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Thought Leadership')} to={externalUrls.LEADERSHIP}>
                {copy.footer.link.thoughtLeadership}
              </FooterLink>
            </FooterLinkWrapper>
          </FooterLinksGroup>
          <FooterLinksGroup>
            <FooterHeading>{copy.footer.heading.company}</FooterHeading>
            <FooterLinkWrapper>
              <FooterLink onClick={() => handleTrackClickNav('Careers')} to={paths.CAREERS}>
                {copy.footer.link.careers}
              </FooterLink>
              <FooterLink
                onClick={() => handleTrackClickNav('External Communications')}
                to={paths.EXTERNAL_COMMUNICATIONS_POLICY}
              >
                {copy.footer.link.externalCommunicationsPolicy}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Leadership Team')} s to={paths.LEADERSHIP_TEAM}>
                {copy.footer.link.leadershipTeam}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Press')} s to={paths.PRESS}>
                {copy.footer.link.press}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Privacy Policy')} to={paths.PRIVACY_POLICY}>
                {copy.footer.link.privacyPolicy}
              </FooterLink>
              <FooterLink onClick={() => handleTrackClickNav('Terms of Service')} s to={paths.TERMS_OF_SERVICE}>
                {copy.footer.link.termsOfService}
              </FooterLink>
            </FooterLinkWrapper>
          </FooterLinksGroup>
        </FooterNavigationSection>
      </FooterContainer>
      <EmailsContainer>
        <p>
          {copy.footer.email.pressEmailLabel}{' '}
          <EmailLink
            onClick={() => handleTrackClickNav('Press Email')}
            href={`mailto:${copy.footer.email.pressEmailAddress}`}
          >
            {copy.footer.email.pressEmailAddress}
          </EmailLink>
        </p>
        <Divider>•</Divider>
        <p>
          {copy.footer.email.generalEmailLabel}{' '}
          <EmailLink
            onClick={() => handleTrackClickNav('General Email')}
            href={`mailto:${copy.footer.email.generalEmailAddress}`}
          >
            {copy.footer.email.generalEmailAddress}
          </EmailLink>
        </p>
      </EmailsContainer>
    </FlexContainer>
  );
};
