import React, {type PropsWithChildren, createContext, useContext, useEffect, useState} from 'react';

import {useLocation} from '@reach/router';

export interface UTMParams {
  campaignId?: string | null;
  leadSource?: string | null;
  sourceType?: string | null;
  nominatorId?: string | null;
  utm?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
}

const UTMContext = createContext<UTMParams | null>(null);

const useUTMParams = (): UTMParams | null => {
  const context = useContext(UTMContext);

  if (context === undefined) {
    throw new Error('useUTMParams must be used within an UTMProvider context.');
  }

  return context;
};

const STORAGE_KEY = 'utmParams';

const INITIAL_VALUE = null;

const UTM_REQUIRED_QUERY_PARAMS = new Set(['utm_campaign', 'utm_medium', 'utm_source']);

const retrieveFromLocalStorage = () => {
  try {
    const data = window.localStorage.getItem(STORAGE_KEY);

    return data ? JSON.parse(data) : INITIAL_VALUE;
  } catch (error) {
    return INITIAL_VALUE;
  }
};

const persistToLocalStorage = (utmParams: UTMParams) => {
  const data = JSON.stringify(utmParams);

  window.localStorage.setItem(STORAGE_KEY, data);
};

const urlContainsAllRequiredUTMParams = (queryParams: URLSearchParams): boolean => {
  let count = 0;

  for (const [param] of queryParams.entries()) {
    if (UTM_REQUIRED_QUERY_PARAMS.has(param)) count++;
  }

  return count === UTM_REQUIRED_QUERY_PARAMS.size;
};

const UTMProvider = ({children}: PropsWithChildren<{children: React.ReactNode}>): JSX.Element => {
  const location = useLocation();
  const [utmParams, setUTMParams] = useState<UTMParams | null>(retrieveFromLocalStorage);

  useEffect(() => {
    if (utmParams === null) return;

    persistToLocalStorage(utmParams);
  }, [utmParams]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (!urlContainsAllRequiredUTMParams(queryParams)) return;

    setUTMParams({
      campaignId: queryParams.get('campaign_id'),
      leadSource: queryParams.get('lead_source'),
      sourceType: queryParams.get('source_type'),
      nominatorId: queryParams.get('nominator_id'),
      utm: queryParams.get('utm'),
      utmCampaign: queryParams.get('utm_campaign'),
      utmContent: queryParams.get('utm_content'),
      utmMedium: queryParams.get('utm_medium'),
      utmSource: queryParams.get('utm_source'),
    });
  }, [location.search, setUTMParams]);

  return <UTMContext.Provider value={utmParams}>{children}</UTMContext.Provider>;
};

export {UTMProvider, useUTMParams};
