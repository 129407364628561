import React from 'react';

import {trackPageView} from 'src/analytics';
import {BaseLayout} from 'src/components/shared';
import {UTMProvider} from 'src/hooks/useUTMParams';
import {defaultTheme} from 'src/styleguide/defaultTheme';
import {ThemeProvider, createGlobalStyle} from 'styled-components/macro';
import './src/styles/main.css';
import './src/index.css';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.offBlack}
  }
`;

const PATHS_TO_HIDE_BASE_LAYOUT = ['/page-builder/draft'];

export function wrapPageElement({element, props}) {
  const isPathToHideBaseLayout = PATHS_TO_HIDE_BASE_LAYOUT.some(path => props.location.pathname.startsWith(path));
  const hideBaseLayout = props.pageContext.hideBaseLayout || isPathToHideBaseLayout;
  const zeroGlobalStyle = props.pageContext.zeroGlobalStyle;

  // Used for the iframe lead form feature
  if (zeroGlobalStyle) {
    return element;
  }

  if (hideBaseLayout) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <UTMProvider>
          <GlobalStyle />
          {element}
        </UTMProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <UTMProvider>
        <GlobalStyle />
        <BaseLayout path={element.props.location.pathname}>{element}</BaseLayout>
      </UTMProvider>
    </ThemeProvider>
  );
}

export const onClientEntry = () => {
  if (window.lintrk) {
    window.lintrk('track', {conversion_id: process.env.GATSBY_LINKEDIN_CONVERSION_ID});
  }

};

export const onRouteUpdate = () => {
  trackPageView();
};
